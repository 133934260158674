// Set your brand colors
$green: #6bc2c2;
$pink:  #ff006a;
$light-green: #6bc2c2;
$grey-light: hsl(0, 0%, 71%);
$white:#faffff;
$danger: #ff5b45;
$dark: #2f7678;

@use "bulma/sass" with (
  $primary: $green,
  $link: $pink,
  $dark: $dark,
  $code: $dark,
  $body-background-color: $white,
  $grey-light: $grey-light,
  $family-primary: '"Readex Pro", serif',
  $family-secondary: '"Readex Pro", serif',
  $weight-bold: 900,
  $scheme-invert: #6b9080,
  $control-border-width: 2px,
  $input-shadow: none,
  $notification-padding: 2.25rem 4rem 4rem 2.5rem
);

// @use "bulma/bulma";
@use "bulma/sass/utilities/mixins";

// Bulma Switch (https://wikiki.github.io/form/switch/)
$switch-background: $grey-light;
$switch-focus: 0;
@import "bulma-switch/dist/css/bulma-switch.min.css";

body, html {
  background-color: $white;
}
.footer {
  background-color: $dark;
  padding: 2rem 1.5rem 2.5rem;
  a {
    text-decoration: underline dotted;
  }
}
.navbar-item img {
  max-height: 4.75rem;
}
a.navbar-item:focus, 
a.navbar-item:hover,
a.navbar-item.is-active {
  background-color: #266364;
  cursor: pointer;
  color: #fff;
}

a:hover {
  color: #333
}


.box {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 .5em 0 -.125em #6b90801a,0 0 0 1px #6b908005;
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
}
.button {
  border-style: solid;
  border-width: 1px 1px 4px;
  font-size: .85rem;
  font-weight: 700;
  height: 2.648em;
  outline: none;
  text-transform: uppercase;
  transition: all .3s ease;
}
.button.is-primary {
  // background-color: #6bc2c2;
  border-color: #0000;
  color: #fff;
}
.button.is-medium {
  font-size: 1.25rem;
  border: $dark solid;
  border-width: 1px 1px 4px;
}
.button {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px 1px 4px;
}
.button.is-ghost {
  color: $pink;
}
.card-footer-item {
  padding: calc(.5em - 2px) 1em;
}
hr {
  background-color: #6bc2c2;
  border: none;
  display: block;
  height: 2px;
  margin: 1.5rem 0;
}
.tag.is-danger {
  background-color: #ff5b45;
  color:#fff;
}
// //////////////////////////////////


.content {
  // Styles for within the WYSIWYG
  hr {
    background-color: #333;
    opacity: 0.5;
  }
}

// DATA tooltip
// https://codepen.io/mildrenben/pen/rVBrpK

.tooltip {
  position: relative;
  &:after {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    content: attr(data-tooltip);
    padding: 6px 10px;
    top: 1.4em;
    left: 50%;
    transform: translateX(-50%) translateY(-2px);
    background: --bulma-primary;
    color: white;
    white-space: nowrap;
    z-index: 2;
    border-radius: 2px;
  }
  &:hover {
    &:after {
      display: block;
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) translateY(0);
    }
  }
}


// Sticky footer
html,
body,
div#root {
  height: 100%;
}
div#root > .base {
  position: sticky;
  top: 100vh;
  margin-top: 10vh;
}

.footer-abstract {
  position: sticky;
  display: block;
  bottom: 20vh;
  top: 0;
  min-width: 100vw;
  img {
    position: absolute;
    right: 0;
    bottom: -20vh;
  }
}

ul.page-site-list li {
  border-bottom: #daeae8 solid 1px;
  padding: 0.3em;
}

// Hide hr on last li of list pages
.page-site-list li:last-child {
  border-bottom: none;
}

label > input {
  margin-right: 0.5em;
}

h2.title {
  font-size: clamp(1.5rem, 4vw, 3.1rem);
  margin-left: 0.4em;
  font-weight: bolder;
}

.title span.icon {
  margin-right: 0.75em;
  transform: translateY(0.17em);
}

// Login box
.form-box {
  margin: auto;
  margin-top: 5.75rem;
  /* margin-bottom: 0; */
  max-width: 640px;
  position: relative;
  z-index: 100;
}

// Website title in the navbar
.navbar-item.website-title {
  > span:first-letter {
    text-transform: uppercase;
  }
  border-right: 1px solid;
  padding-left: .9rem;
  padding-right: 1.25rem;
  font-weight: 900;
}
.navbar-item.website-title + .navbar-item {
  padding-left: .9rem;
}

// Force show navbar menu on mobile
.navbar-menu {
  display: flex !important;
  @include mixins.mobile {
    display: block !important;
  }
}

// Scrollable navbar on mobile
.navbar-start {
  display: flex !important;
  @include mixins.mobile {
    display: block !important;
  }
}

// Log out button absolute position
.logout-button {
  position: absolute;
  top: 0.75em;
  right: 0.75em;
}

.svg-inline--fa {
  filter: drop-shadow(0 -1px 0 #fff) drop-shadow(0 2px 0 rgba(0, 0, 0, 0.08));
}

// Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;700&display=swap");

.title {
  font-family: "Readex Pro", serif;
  font-weight: 700;
}

body {
  font-family: "Readex Pro", serif;
}

// https://css-tricks.com/a-handy-little-system-for-animated-entrances-in-css

.animate {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate.pop {
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

@keyframes animate-pop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.delay-1 {
  animation-delay: 0.1s;
}

.delay-2 {
  animation-delay: 0.7s;
}

.delay-3 {
  animation-delay: 0.8s;
}

.box {
  border: solid 1px #cfe8e8;
}

[role="textbox"] {
  cursor: text;
}

.image-preview {
  width: 100px;
  @include mixins.desktop {
    width: 250px;
  }
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    &:after {
      @include mixins.loader;
      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(125px - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
    }
  }
}

.drag-handle {
  cursor: move !important;
}

.navbar {
  z-index: 8;
}

.clickable {
  cursor: grab;
}

.card-header {
  background-color: #ededed;
}

.add-row {
  border-style: dashed;
  border-width: 2px;
}

.content-module-columns {
  @include mixins.tablet {
    display: flex;
    overflow-x: auto;
  }
}
